import StyledInput from "./StyledInput";

const Input = (props) => {
  const { placeholder, type, name, onClick, value, onChange, id } = props;

  return (
    <StyledInput
      id={id}
      placeholder={placeholder}
      type={type}
      name={name}
      onChange={onChange}
      onClick={onClick}
      value={value}
    ></StyledInput>
  );
};

export default Input;
