import styled from "styled-components";

const StyledMainPhoto = styled.div`

margin-top: 30px;
display: flex;
align-items: center;
justify-content: center;
margin-top: 2rem;
width: 100vw;
overflow-x: hidden;

  img {
    border-radius: 1.5rem;
    max-width: 90vw;
    max-height: 140vh;
   margin-left: 0;
   margin-right: 0;
    
  }
`;

export default StyledMainPhoto;
