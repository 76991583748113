// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDh6ebI2O72bPWQl4KfO7Y3e8EWCnUXrmw",
  authDomain: "wedding-dawid.firebaseapp.com",
  projectId: "wedding-dawid",
  storageBucket: "wedding-dawid.appspot.com",
  messagingSenderId: "248848824986",
  appId: "1:248848824986:web:24a0625ff872ffd4f27d23"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
