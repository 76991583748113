import styled from "styled-components";

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  margin-top: 7rem;
  margin-bottom: 1rem;
  opacity: 0.8;
  font-weight: bold;

  padding: 0;
  margin-left: 0;
  margin-right: 0;

  h2 {
    margin: 10px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: #c8ccc3;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export default StyledFooter;
