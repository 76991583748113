import { React } from "react";
import StyledPage from "../components/StyledPage";
import GalleryOnClick from "../components/GalleryOnClick";
import GalleryAllPhotosOnPage from "../components/GalleryAllPhotosOnPage";

const Photos = (props) => {
  return (
    <StyledPage>
      <h2>Zdjęcia</h2>

      {/* <GalleryOnClick></GalleryOnClick> */}

      <GalleryAllPhotosOnPage
        firstPhotoNumber={2}
        sumPhotos={103}
        folderOfPhotos={"przygotowanie"}
        namePhotos={"Perfect View prz-1-"}
      ></GalleryAllPhotosOnPage>

      <GalleryAllPhotosOnPage
        firstPhotoNumber={2}
        sumPhotos={102}
        folderOfPhotos={"ceremonia"}
        namePhotos={"Perfect View c-1-"}
      ></GalleryAllPhotosOnPage>

      <GalleryAllPhotosOnPage
        firstPhotoNumber={1}
        sumPhotos={250}
        folderOfPhotos={"wesele"}
        namePhotos={"Perfect View w-1-"}
      ></GalleryAllPhotosOnPage>

<GalleryAllPhotosOnPage
        firstPhotoNumber={1}
        sumPhotos={105}
        folderOfPhotos={"plener"}
        namePhotos={"Perfect View prz-1-"}
      ></GalleryAllPhotosOnPage>


    </StyledPage>
  );
};

export default Photos;
