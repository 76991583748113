import { React, useState, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import ThanksVideo from "./pages/ThanksVideo";
import Movie from "./pages/Movie";
import Photos from "./pages/Photos";
import Nav from "./components/Nav";
import Title from "./components/Title";
import StyledText from "./components/StyledText";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import StyledPage from "./components/StyledPage";
import { Context } from "./components/Context";
import { auth } from "./components/Firebase";
import LoginForm from "./components/LoginForm";
import Input from "./components/Input";
import Footer from "./components/Footer";
import MainPhoto from "./components/MainPhoto";
import Home from "./pages/Home";

function App() {
  const appState = {
    pageName: "",
    photoNumber: 11, //numer pierwszego zdjęcia w galerii onClick
  };

  const [state, setState] = useState(appState);

  const context = {
    setState: setState,
    state: state,
  };

  const handleSignOut = () => {
    auth.signOut();
  };

  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("user", user.uid);
      } else {
        setCurrentUser(null);
        localStorage.removeItem("user");
      }
    });
  }, []);

  return (
    <>
      {currentUser ? (
        <>
          <Context.Provider value={context}>
            <Router>

              <StyledPage>

                <Nav></Nav>

                <Input
                  id={"logout-buttony"}
                  type={"submit"}
                  value={"Wyloguj"}
                  onClick={handleSignOut}
                ></Input>
                
                <Title></Title>
               
                {/* {state.pageName === "home" || null ? (
                  <>
                    <StyledText>Dziękujemy, że byliście z nami!</StyledText>
                    <MainPhoto></MainPhoto>
                    <Footer></Footer>
                    
                    <NavLink to="/thanksVideo">
                      <FontAwesomeIcon
                        className="iconHeart"
                        icon={faHeart}
                      ></FontAwesomeIcon>
                    </NavLink>
                    
                  </>
                ) : null} */}


         

              <Switch>
                <Route exact path="/">
                  <Home></Home>
                </Route>

                <Route path="/photos">
                  <Photos></Photos>
                </Route>

                <Route path="/movie">
                  <Movie></Movie>
                </Route>
              </Switch>

              <Footer></Footer>

              </StyledPage>

            </Router>
            
          </Context.Provider>
        </>
      ) : (
        <>
          <Title></Title>
          <LoginForm></LoginForm>
        </>
      )}
    </>
  );
}

export default App;
