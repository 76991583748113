import React from "react";
import StyledText from "../components/StyledText";
import MainPhoto from "../components/MainPhoto";

const Home = () => {
  return (
    <>
      <StyledText>Dziękujemy, że byliście z nami!</StyledText>
      <MainPhoto></MainPhoto>
    </>
  );
};

export default Home