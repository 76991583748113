import YouTube from 'react-youtube'
import StyledPage from '../components/StyledPage'

const ThanksVideo = () => {
    // const opts = {
    //     height: 'auto',
    //     width: 'auto',
    // }



    return (
        <StyledPage>
        <YouTube videoId='-Y3Ls7uM6Vs' iframeClassName='youTube' ></YouTube>

        <YouTube videoId='m9Tsb31uMYk' iframeClassName='youTube' ></YouTube>

        <YouTube videoId='-u_Qcbln-eo' iframeClassName='youTube' ></YouTube>


        </StyledPage>
    )
}

export default ThanksVideo