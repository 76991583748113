import styled from "styled-components";

const StyledPage = styled.div`
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  

  .iconHeart {
    color: #ff1a1a;
    margin: 0;
    padding: 0;
    font-size: 9rem;
  }

  .iconHeart :hover {
    transition: all 1s ease-in-out;
    color: pink;
  }
`;

export default StyledPage;
