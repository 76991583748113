import styled from "styled-components";

const StyledContainer = styled.div`
width: 100vw;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
flex-direction: column;
overflow-x: hidden;

img{
    border-radius: 1.5rem;
    max-width: 75vw;

    padding: 0.5rem;
    margin: 0.5rem;
   
}

`

export default StyledContainer