import styled from "styled-components";

const StyledText = styled.p`
display: flex;
align-items: center;
justify-content: center;
font-size: 3.5rem;
font-family: 'Bonheur Royale', cursive; 
margin: 0;
padding: 0;

@media (max-width: 600px) {
    font-size: 2rem;
  }
`

export default StyledText