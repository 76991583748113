import StyledForm from "./StyledForm";

const Form = (props) => {

const {children} = props

    return(
        <StyledForm>{children}</StyledForm>
    )
}

export default Form