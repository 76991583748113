import StyledFooter from "./StyledFooter";

const Footer = () => {
  return (
    <StyledFooter>
      <h2>
        <a href="https://perfectview.com.pl/">PERFECT VIEW</a>
      </h2>
      ||
      <h2>
        <a href="http://www.zajazdwbialej.pl/">ZAJAZD W BIAŁEJ</a>
      </h2>
      ||
      <h2>
        <a href="https://cozawesele.com/">COZAWESELE.COM</a>
      </h2>
      ||
      <h2>
        <a href="https://www.facebook.com/DjAdamRybicki">DJ ADAM RYBICKI</a>
      </h2>
    </StyledFooter>
  );
};

export default Footer;
