import styled from "styled-components";

const StyledForm = styled.form`

display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

`

export default StyledForm