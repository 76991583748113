import { NavLink } from "react-router-dom";
import StyledNav from "./StyledNav";
import { useContext } from "react";
import { Context } from "./Context";

const Nav = () => {
  const context = useContext(Context);

  const activeStyle = {
    transition: "all 1s ease-in-out",

    borderBottom: "1px solid black",
  };

  return (
    <StyledNav>
      <li>
        <NavLink
          onClick={() => {
            localStorage.setItem("page", "home");
            const pageName = localStorage.getItem('page')

            context.setState({
              pageName: pageName,
              photoNumber: context.state.photoNumber,
            });
        
          }}
          exact
          activeStyle={activeStyle}
          to="/"
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={() =>{
            localStorage.setItem("page", "photos");
            const pageName = localStorage.getItem('page')
            context.setState({
              pageName: pageName,
              photoNumber: context.state.photoNumber,
            })
          }}
          activeStyle={activeStyle}
          to="/photos"
        >
          Zdjęcia
        </NavLink>
      </li>

      <li>
        <NavLink
          onClick={() =>{
            localStorage.setItem("page", "movie");
            const pageName = localStorage.getItem('page')
            context.setState({
              pageName: pageName,
              photoNumber: context.state.photoNumber,
            })
          }}
          activeStyle={activeStyle}
          to="/movie"
        >
          Film
        </NavLink>
      </li>
    </StyledNav>
  );
};

export default Nav;
