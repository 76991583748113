import styled from "styled-components";

const StyledNav = styled.ul`
  display: flex;
  justify-content: end;
  padding-right: 3rem;

  li {
    list-style: none;
    padding: 0.5rem;
  }
  li a {
    text-decoration: none;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
`;

export default StyledNav;
