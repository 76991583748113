import StyledTitle from "./StyledTitle"


const Title = () => {

    return(
        
        <>
        <StyledTitle>
            Asia i Dawid
        </StyledTitle>
       
            </>
    )
}


export default Title