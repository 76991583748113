import styled from "styled-components";

const StyledInput = styled.input`
  border-radius: 5px;
  height: 35px;
  border: none;
  background-color: white;
  margin: 10px;
  font-family: 'Roboto';
  font-weight: bold;
  cursor: ${(props) => (props.type === "submit" ? "pointer" : "auto")};
  :hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
      -2px -2px 4px rgba(255, 255, 255, 0.4),
      2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
`;

export default StyledInput;
