import styled from 'styled-components'

const StyledTitle = styled.h2`
 font-size: 7rem;
  font-weight: 100;
  text-align: center;
  font-family: 'Bonheur Royale', cursive; 
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  margin-top: 2rem;

  @media (max-width: 600px) {
    font-size: 4rem;
  }
`

export default StyledTitle