import StyledPage from "../components/StyledPage";
import ThanksVideo from "./ThanksVideo";

const Movie = () => {
  return (
    <StyledPage>
     
    <ThanksVideo></ThanksVideo>
    </StyledPage>
  );
};

export default Movie;
