import React from "react";
import Container from "./Container";

const GalleryAllPhotosOnPage = (props) => {

const {firstPhotoNumber, sumPhotos, folderOfPhotos, namePhotos} = props

    let firstNumberPhoto = firstPhotoNumber // wpisz numer pierwszego zdjęcia
    const numberOfPhotos = sumPhotos // wpisz ilość zdjęć

    let number = firstNumberPhoto - 1;


    return(
    <Container>
        {Array(numberOfPhotos)
          .fill()
          .map((element, index) => {
            number = number + 1;
            return (
              <img
                key={index}
                src={require(`../img/${folderOfPhotos}/${namePhotos}${number}.jpg`)}
                alt="foto"
              ></img>
            );
          })}
      </Container>
    )
}

export default GalleryAllPhotosOnPage